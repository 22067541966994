import React from "react";

const ListProduct = () => {
    return(
        <>
            <h1>ListProduct</h1>
            
        </>
    )

}

export default ListProduct;