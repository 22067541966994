import Navbar from '../partial/navbar';
import Footer from '../partial/footer';
import '../Style/login.css'
import VerifyMailComponent from '../component/VerifyMailComponent';
const VerifyMail = () => {
    return (
        <>
            {/* <Navbar /> */}
            <VerifyMailComponent />
            {/* <Footer /> */}
        </>
    );
}

export default VerifyMail;