import React from "react";

const UpdateProduct = () => {
    return(
        <>
            <h1>UpdateProduct</h1>
        </>
    )

}

export default UpdateProduct;