import React from "react";

const AddProduct = () => {
    return(
        <>
            <h1>Add Product</h1>
        </>
    )

}

export default AddProduct;