import React from "react";

const ListUser = () => {
    return(
        <>
            <h1>List User</h1>
        </>
    )

}

export default ListUser;