import Navbar from '../partial/navbar';
import Footer from '../partial/footer';
import '../Style/login.css'
import ForgetPasswordComponent from '../component/ForgetPasswordComponent';
const ForgetPassword = () => {
    return (
        <>
            {/* <Navbar /> */}
            <ForgetPasswordComponent />
            {/* <Footer /> */}
        </>
    );
}

export default ForgetPassword;